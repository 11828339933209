@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

$typeface: 'Manrope', sans-serif;

@mixin typeface-01($size, $device: desktop) {
    font-family: $typeface;

    @if $size == h1 and $device == desktop {
        font-size: 4.2rem;
        color: $font_color--secondary;

    } @else if $size == h1 and $device == mobile {
        font-size: 2.3rem;
        color: $font_color--secondary;

    } @else if $size == h1 and $device == xs {
        font-size: 1.3rem;
        color: $font_color--secondary;

    } @else if $size == h2 and $device == desktop {
        font-size: 1.8rem;
        
    } @else if $size == h2 and $device == mobile {
        font-size: 1.6rem;
        
    } @else if $size == h2 and $device == xsmobile {
        font-size: 1.5rem;
        
    } @else if $size == h3 and $device == desktop {
        font-size: 2.5rem;

    } @else if $size == h3 and $device == mobile {
        font-size: 2.2rem;

    } @else if $size == h4 and $device == desktop {
        font-size: 2.2rem;

    } @else if $size == h4 and $device == mobile {
        font-size: 2rem;

    } @else if $size == h5 and $device == desktop {
        font-size: 2rem;

    } @else if $size == h5 and $device == mobile{
        font-size: 1.8rem;

    } @else if $size == h6 and $device == desktop {
        font-size: 1.8rem;

    } @else if $size == h6 and $device == mobile {
        font-size: 1.6rem;
    }
}

@mixin typeface-02($size, $device: desktop) {
    font-family: $typeface;

    @if $size == p1 and $device == desktop {
        font-size: 1.6rem;
        line-height: 100%;

    } @else if $size == p1 and $device == mobile {
        font-size: 1.4rem;
        line-height: 100%;


    } @else if $size == p2 and $device == desktop {
        font-size: 1.8rem;
        line-height: 100%;

        
    } @else if $size == p2 and $device == mobile {
        font-size: 1.6rem;
        line-height: 100%;

        
    } @else if $size == p3 and $device == desktop {
        font-size: 1.4rem;
        line-height: 100%;


    } @else if $size == p3 and $device == mobile {
        font-size: 1.2rem;
        line-height: 100%;

    } 
}


