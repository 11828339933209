@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 2rem 3rem;
  overflow: hidden;
}
.container::after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 860px) {
  .container {
    padding: 1rem 1rem;
  }
}
@media screen and (max-width: 552px) {
  .container {
    padding: 1rem 1rem;
  }
}

.grid-1 {
  width: calc(8.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-1 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-2 {
  width: calc(16.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-2 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-3 {
  width: calc(25% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-3 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-4 {
  width: calc(33.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-4 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-5 {
  width: calc(41.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-5 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-6 {
  width: calc(50% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-6 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-7 {
  width: calc(58.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-7 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-8 {
  width: calc(66.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-8 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-9 {
  width: calc(75% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-9 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-10 {
  width: calc(83.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-10 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-11 {
  width: calc(91.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-11 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-12 {
  width: calc(100% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 860px) {
  .grid-12 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: none;
  }
}

.grid-nobreak-1 {
  width: calc(8.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-2 {
  width: calc(16.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-3 {
  width: calc(25% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-4 {
  width: calc(33.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-5 {
  width: calc(41.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-6 {
  width: calc(50% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-7 {
  width: calc(58.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-8 {
  width: calc(66.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-9 {
  width: calc(75% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-10 {
  width: calc(83.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-11 {
  width: calc(91.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-nobreak-12 {
  width: calc(100% - 1.6rem);
  margin: 0.8rem;
  float: left;
}

.grid-card-1 {
  width: calc(8.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-1 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-1 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-1 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-2 {
  width: calc(16.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-2 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-2 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-2 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-3 {
  width: calc(25% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-3 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-3 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-3 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-4 {
  width: calc(33.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-4 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-4 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-4 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-5 {
  width: calc(41.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-5 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-5 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-5 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-6 {
  width: calc(50% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-6 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-6 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-6 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-7 {
  width: calc(58.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-7 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-7 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-7 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-8 {
  width: calc(66.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-8 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-8 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-8 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-9 {
  width: calc(75% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-9 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-9 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-9 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-10 {
  width: calc(83.3333333333% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-10 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-10 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-10 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-11 {
  width: calc(91.6666666667% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-11 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-11 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-11 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.grid-card-12 {
  width: calc(100% - 1.6rem);
  margin: 0.8rem;
  float: left;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .grid-card-12 {
    width: calc(50% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 860px) {
  .grid-card-12 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
  }
}
@media screen and (max-width: 370px) {
  .grid-card-12 {
    width: calc(100% - 1.6rem);
    margin: 0.8rem;
    float: left;
  }
}

.flex {
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero {
  width: 100%;
}

header {
  width: 100%;
  position: sticky;
  z-index: 100;
  top: 0;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 400;
}
.menu li {
  margin-left: 3.2rem;
}
.menu li > .active {
  color: #000;
}
.menu .nav-link.active {
  color: #000;
}
.menu .nav-link {
  display: inline-block;
  padding: 0.8rem;
  transition: 0.5s ease;
}
.menu .nav-link:hover {
  color: #000;
}
@media screen and (max-width: 860px) {
  .menu .nav-link {
    width: 100%;
  }
}
@media screen and (max-width: 552px) {
  .menu .nav-link {
    width: 100%;
  }
}
.menu.social-media {
  color: #000;
  position: relative;
  font-weight: 400;
  font-size: 1.5rem;
}
.menu.social-media > li {
  width: auto;
  margin-left: 0;
}
.menu.social-media .nav-link:hover {
  color: #000;
  position: relative;
  font-weight: 400;
  transition: 0.35s;
}
.menu.social-media .nav-link:hover::before {
  transform: translate(-0.6rem, -0.2rem) scale(1.5);
  transition: 0.35s;
}
.menu.social-media .nav-link {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  transition: 0.35s;
}
.menu.social-media .nav-link::before {
  display: block;
  content: url(./../assets/svg/arrow.svg);
  background-size: 28px 28px;
  transform: translate(0, -0.2rem) scale(1);
  transition: 0.35s;
}
@media screen and (max-width: 860px) {
  .menu.social-media > li {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 552px) {
  .menu.social-media > li {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 860px) {
  .menu {
    justify-content: space-around;
  }
  .menu li {
    margin-left: 0;
  }
  .menu .nav-link {
    padding: 1.6rem 1.2rem;
  }
}
@media screen and (max-width: 552px) {
  .menu {
    justify-content: space-around;
  }
  .menu li {
    margin-left: 0;
  }
  .menu .nav-link {
    padding: 1.6rem 1.2rem;
  }
}

/*
---------------------------------------
hover effect 25
---------------------------------------
*/
.SMN_effect-25 a {
  letter-spacing: inherit;
  transition: 0.35s;
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .SMN_effect-25 a {
    letter-spacing: inherit;
    transition: 0;
  }
}
@media screen and (max-width: 860px) {
  .SMN_effect-25 a {
    letter-spacing: inherit;
    transition: 0;
  }
}
@media screen and (max-width: 552px) {
  .SMN_effect-25 a {
    letter-spacing: inherit;
    transition: 0;
  }
}

.SMN_effect-25 a:hover, .SMN_effect-25 a:active {
  letter-spacing: 2px;
  transition: 0.35s;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .SMN_effect-25 a:hover, .SMN_effect-25 a:active {
    letter-spacing: inherit;
    transition: 0;
  }
}
@media screen and (max-width: 860px) {
  .SMN_effect-25 a:hover, .SMN_effect-25 a:active {
    letter-spacing: inherit;
    transition: 0;
  }
}
@media screen and (max-width: 552px) {
  .SMN_effect-25 a:hover, .SMN_effect-25 a:active {
    letter-spacing: inherit;
    transition: 0;
  }
}

.hero_home {
  height: 100vh;
  overflow-y: auto;
  position: relative;
}
@media screen and (max-width: 860px) {
  .hero_home {
    padding-bottom: 80vh;
  }
}

.card {
  margin-bottom: 6rem;
}
@media screen and (max-width: 860px) {
  .card {
    margin-bottom: 2rem;
  }
}

.port-container {
  padding: 6rem 0 4rem 3rem;
}
.port-container h1 {
  white-space: pre;
  font-size: 3.5rem;
  font-weight: 400;
  color: #000;
}
@media screen and (max-width: 860px) {
  .port-container {
    padding: 6rem 0 4rem 0;
  }
}

.port-title {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  white-space: pre;
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .port-title h1 {
    font-size: 3rem;
    color: #000;
  }
}
@media screen and (max-width: 860px) {
  .port-title h1 {
    font-size: 2.5rem;
    color: #000;
  }
}
@media screen and (max-width: 552px) {
  .port-title h1 {
    font-size: 2rem;
    color: #000;
  }
}
@media screen and (max-width: 450px) {
  .port-title h1 {
    font-size: 1.8rem;
    color: #000;
  }
}
@media screen and (max-width: 400px) {
  .port-title h1 {
    font-size: 1.5rem;
    color: #000;
  }
}
@media screen and (max-width: 350px) {
  .port-title h1 {
    font-size: 4vw;
    color: #000;
  }
}
@media screen and (max-width: 552px) {
  .port-title {
    justify-content: center;
    text-align: center;
  }
}

.card-container {
  width: 100%;
  padding: 0 3rem;
  opacity: 0;
}
@media screen and (max-width: 860px) {
  .card-container {
    padding: 0;
    position: absolute;
  }
}

.thumb {
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08), 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
}
.thumb:hover > .btn-external-link {
  background-color: #000;
  opacity: 1;
  transition: 0.35s;
}
.thumb .btn-external-link {
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #d4cfc4;
  opacity: 0.6;
  transition: 0.35s;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.thumb .btn-external-link img {
  height: 1rem;
}

.card-info {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.card-info .play-min {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
}
.card-info .play-min .play-icon img {
  height: 0.8rem;
}
.card-info .play-min .min-icon {
  font-size: 1.5rem;
}
@media screen and (max-width: 1440px) and (min-width: 1140px) {
  .card-info .play-min .min-icon {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .card-info .play-min .min-icon {
    font-size: 1rem;
  }
}
@media screen and (max-width: 860px) {
  .card-info .play-min .min-icon {
    font-size: 1rem;
  }
}
.card-info .card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
}
.card-info .card-details .card-subtitle {
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  color: #000;
}

footer {
  width: 100%;
}
footer .container {
  padding: 0rem 1rem;
}

.footer {
  display: flex;
  padding: 12px 0;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.footer p {
  font-family: "Manrope", sans-serif;
  font-size: 1.6rem;
  line-height: 100%;
}
@media screen and (max-width: 860px) {
  .footer p {
    font-family: "Manrope", sans-serif;
    font-size: 1.2rem;
    line-height: 100%;
  }
}

.post-container {
  padding: 0;
}
.post-container .video-player {
  padding: 56.25% 0 0 0;
  position: relative;
}
.post-container .video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post-reel {
  padding: 0;
}
.post-reel .video-player {
  padding: 56.25% 0 0 0;
  position: relative;
}
.post-reel .video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 860px) {
  .post-reel .video-player {
    padding: 120% 0 0 0;
  }
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .post-reel .video-player {
    padding: 100% 0 0 0;
  }
}

.post-info {
  height: 0;
  padding-bottom: 37%;
  overflow-y: auto;
  position: relative;
}
@media screen and (min-width: 1440px) {
  .post-info {
    overflow-y: auto;
  }
}
@media screen and (max-width: 860px) {
  .post-info {
    height: 52vh;
    padding-bottom: 5%;
    overflow-y: auto;
    position: unset;
  }
}

.post-info .post-content {
  position: absolute;
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}
@media screen and (max-width: 860px) {
  .post-info .post-content {
    position: unset;
  }
}
.post-info .post-content .play-min {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
  gap: 1rem;
}
.post-info .post-content .play-min .play-icon img {
  height: 0.8rem;
}
.post-info .post-content .play-min .min-icon {
  font-size: 1.5rem;
}
@media screen and (max-width: 1440px) and (min-width: 1140px) {
  .post-info .post-content .play-min .min-icon {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  .post-info .post-content .play-min .min-icon {
    font-size: 1rem;
  }
}
@media screen and (max-width: 860px) {
  .post-info .post-content .play-min .min-icon {
    font-size: 1rem;
  }
}
.post-info .post-content .post-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
}
.post-info .post-content .post-details .post-title {
  font-family: "Manrope", sans-serif;
  font-size: 2.3rem;
  color: #000;
  color: #000;
}
.post-info .post-content .post-details .post-subtitle {
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  color: #000;
}
.post-info .post-content .post-description p {
  font-family: "Manrope", sans-serif;
  font-size: 1.6rem;
  line-height: 100%;
  color: #000;
  line-height: 130%; /* 17.6px */
  font-weight: 100;
}
.post-info .post-content .post-role span {
  display: inline;
  font-family: "Manrope", sans-serif;
  font-size: 1.6rem;
  line-height: 100%;
  color: #000;
  font-weight: 100;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  gap: 1rem;
}
.gallery .img-container:hover {
  transition: all 0.1s ease-in-out;
  opacity: 0.7;
}
.gallery .img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
@media screen and (min-width: 1440px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  }
}
@media screen and (max-width: 1440px) and (min-width: 1140px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}
@media screen and (min-width: 666px) and (max-width: 860px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  }
}
@media screen and (max-width: 445px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}
@media screen and (max-width: 1140px) and (min-width: 860px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
}
@media screen and (max-width: 445px) {
  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.full-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  transform: translate(-50%, -50%) scale(0.5);
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 860px) {
  .full-img {
    width: 90%;
  }
}

.full-img.open {
  transform: translate(-50%, -50%) scale(1);
  z-index: 2000;
}

.modal.open {
  opacity: 1;
  pointer-events: all;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.overlay .counter {
  position: absolute;
  z-index: 1500;
  font-size: 23rem;
  color: rgba(255, 255, 255, 0.05);
}
.overlay .birrib_logo {
  z-index: 2000;
  height: 8%;
}
.overlay span {
  color: #fff;
  font-size: 8rem;
}

.about-container {
  height: 78vh;
  overflow-y: auto;
  position: relative;
}
@media screen and (min-width: 1440px) {
  .about-container {
    overflow-y: auto;
  }
}
@media screen and (max-width: 860px) {
  .about-container {
    height: 80vh;
    padding-bottom: 5%;
    overflow-y: auto;
    position: unset;
  }
}

.about {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.about_title {
  font-size: 3.2rem;
}
.about_cta {
  font-size: 1.6rem;
}
.about_description {
  line-height: 130%;
  max-width: 44.9rem;
  font-size: 1.6rem;
  margin-bottom: 4rem;
}
.about_contact-container {
  display: flex;
  flex-direction: column;
}
.about_contact-container .about_email, .about_contact-container .about_phone {
  font-size: 3.2rem;
}
.about_contact-container .about_email::before, .about_contact-container .about_phone::before {
  margin-right: 0.8rem;
  display: inline;
  content: url(./../assets/svg/arrowContact.svg);
  background-size: 28px 28px;
  transform: translate(0, -0.2rem) scale(2px);
  transition: 0.35s;
}

.about_perfil .photo img {
  width: 24rem;
  height: 20.9rem;
}
@media screen and (max-width: 860px) {
  .about_perfil {
    padding-top: 6rem;
  }
}

.perfil_info {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.perfil_info .play-min {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
  padding-top: 0.6rem;
}
.perfil_info .play-min .play-icon img {
  height: 1rem;
}
.perfil_info .play-min .play-icon.world img {
  height: 1.2rem;
}
.perfil_info .perfil-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
}
.perfil_info .perfil-details .perfil-links {
  font-size: 1.2rem;
  display: flex;
  gap: 0.4rem;
  text-decoration: underline;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  color: #000;
}

.about .about_vitae {
  width: 100%;
  padding: 2.4rem;
  background-color: #151515;
}
.about .about_vitae .vitae_container {
  color: #8D8D8D;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.about .about_vitae .vitae_container .vitae_section {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.about .about_vitae .vitae_container .vitae_section--title {
  font-size: 1.6rem;
}
.about .about_vitae .vitae_container .vitae_section--subtitle {
  font-size: 1.4rem;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}

/*
    #RESET
*/
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
span,
button,
ion-icon {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
}

svg, img, video {
  width: 100%;
}

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: "Manrope", sans-serif;
  font-size: 62.5%; /* 16 x 0.625 = 10 */
  scroll-behavior: smooth;
  scroll-padding-top: 30rem;
}

body {
  background-color: #d4cfc4;
  color: #000;
  font-size: 1.6rem;
  overflow-x: hidden;
  height: 100vh;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #d4cfc4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #d4cfc4;
}

:focus-visible {
  outline-offset: 0.4rem;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #d4cfc4;
}

::-webkit-scrollbar-thumb {
  background-color: #d4cfc4;
}

h1 {
  font-family: "Manrope", sans-serif;
  font-size: 4.2rem;
  color: #000;
}

h2 {
  font-family: "Manrope", sans-serif;
  font-size: 1.8rem;
}
@media screen and (max-width: 1440px) and (min-width: 1140px) {
  h2 {
    font-family: "Manrope", sans-serif;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1140px) and (min-width: 552px) {
  h2 {
    font-family: "Manrope", sans-serif;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 860px) {
  h2 {
    font-family: "Manrope", sans-serif;
    font-size: 1.5rem;
  }
}

p {
  font-family: "Manrope", sans-serif;
  font-size: 1.8rem;
  line-height: 100%;
}

@media screen and (max-width: 860px) {
  a {
    font-family: "Manrope", sans-serif;
    font-size: 1.2rem;
    line-height: 100%;
  }
}
@media screen and (max-width: 552px) {
  a {
    font-family: "Manrope", sans-serif;
    font-size: 1.2rem;
    line-height: 100%;
  }
}

.hidden {
  overflow: hidden;
}

@media screen and (max-width: 860px) {
  .cta-desktop {
    display: none;
  }
}

.cta-mobile {
  display: none;
}
@media screen and (max-width: 860px) {
  .cta-mobile {
    display: block;
  }
}

.cta-mobile-border-bottom {
  border: none;
}
@media screen and (max-width: 860px) {
  .cta-mobile-border-bottom {
    border-bottom: 0.025rem solid #999999;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 552px) {
  .cta-mobile-border-bottom {
    border-bottom: 0.025rem solid #999999;
    padding-bottom: 1rem;
  }
}

.bg-noise {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 0.9;
  visibility: visible;
  z-index: -1000;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}/*# sourceMappingURL=main.css.map */