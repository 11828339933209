@import "sass/_colors";
@import "sass/_typography";
@import "sass/_layout";


#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: hidden;
}


/*
    #RESET
*/
*, 
*::before,
*::after { 
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

li { list-style: none;}

a,
img,
span,
button,
ion-icon { display: block;}


a {
    color: inherit;
    text-decoration: none;
}

img { height: auto; }

svg, img, video {
    width: 100%;
}

button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
}

ion-icon { pointer-events: none;}

address{ font-style: normal;}

html {
    font-family: $typeface;
    font-size: 62.5%; /* 16 x 0.625 = 10 */
    scroll-behavior: smooth;
    scroll-padding-top: 30rem;
}

body {
    background-color: $primary;
    color: $font_color--primary;
    font-size: 1.6rem;
    overflow-x: hidden;
    height: 100vh;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 10px;
}
 
::-webkit-scrollbar-track {
    background-color: $primary;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $primary; 
}
:focus-visible { outline-offset: .4rem}

::-webkit-scrollbar { width: .5rem;}

::-webkit-scrollbar-track { background-color: $primary; }

::-webkit-scrollbar-thumb { background-color: $primary; }

h1 {
    @include typeface-01(h1, desktop);
}

h2 {
    @include typeface-01(h2, desktop);

    @include responsive(l) {
        @include typeface-01(h2, mobile);
    }

    @include responsive(m) {
        @include typeface-01(h2, mobile);
    }

    @include responsive(s) {
        @include typeface-01(h2, xsmobile);
    }
}


p {
    @include typeface-02(p2, desktop);
    
}

a {
    @include responsive(s) {
        @include typeface-02(p3, mobile);
    }

    @include responsive(xs) {
        @include typeface-02(p3, mobile);;
    }
    
}

.hidden {
    overflow: hidden;
}

.cta-desktop {
    @include responsive(s) {
        display: none;
    }
}

.cta-mobile {
    display: none;

    @include responsive(s) {
        display: block;
    }
}

.cta-mobile-border-bottom {
    border: none;

    @include responsive(s) {
        border-bottom: .025rem solid #999999;
        padding-bottom: 1rem;
    }

    @include responsive(xs) {
        border-bottom: .025rem solid #999999;
        padding-bottom: 1rem;
    }
}


.bg-noise {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('http://assets.iceable.com/img/noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation .2s infinite;
    opacity: .9;
    visibility: visible;
    z-index: -1000;
  }
  
  @keyframes bg-animation {
      0% { transform: translate(0,0) }
      10% { transform: translate(-5%,-5%) }
      20% { transform: translate(-10%,5%) }
      30% { transform: translate(5%,-10%) }
      40% { transform: translate(-5%,15%) }
      50% { transform: translate(-10%,5%) }
      60% { transform: translate(15%,0) }
      70% { transform: translate(0,10%) }
      80% { transform: translate(-15%,0) }
      90% { transform: translate(10%,5%) }
      100% { transform: translate(5%,0) }
  }