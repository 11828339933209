//Layout of project

//Container-devices
$container-xxl: 1920px;
$container-xl: 1440px;
$container-l: 1140px;
$container-m: 860px;
$container-s: 552px;

@mixin responsive($device) {
    @if $device == xl {
        //For resolutions upper 1440px
        @media screen and (min-width: $container-xl) {
            @content
        }

    } @else if $device == l {

        //For resolution between 1140px and 1440px
        @media screen and (max-width: $container-xl) and (min-width: $container-l) {
            @content;
        }

    } @else if $device == m {

        //For resolution between 960px and 1140px
        @media screen and (max-width: $container-l) and (min-width: $container-s) {
            @content;
        }

    } @else if $device == s {

        //For resolution less then 960px
        @media screen and (max-width: $container-m) {
            @content;
        }

    } @else if $device == xs {

        //For resolution less then 540px
        @media screen and (max-width: $container-s) {
            @content;
        }
    } 
    
    @else if $device == minorXs {

        //For resolution less then 370px
        @media screen and (max-width: 370px) {
            @content;
        }
    } 

    
}


$container: $container-xxl;
$gutter: 1.6rem;

.container {
    //clearfix
    &::after {
        content: '';
        display: table;
        clear: both;
    }
    
    max-width: $container;
    margin: 0 auto;
    padding: 2rem 3rem; 
    //border: 1px solid $accent;
    overflow: hidden;

    //BREAKPOINT 
    @include responsive(s) {
        padding: 1rem 1rem; 
    }

    //BREAKPOINT 
    @include responsive(xs) {
        padding: 1rem 1rem; 
    }
    
}

@function grid($col, $total: 12) {
    @return calc($col / $total) * 100%
}

@for $i from 1 through 12 {
    .grid-#{$i} {
        width: calc(#{grid($i)} - #{$gutter});
        margin: calc($gutter / 2);

        //background-color: $secondary;
        //border: 1px solid $accent;
        float: left;

        //BREAKPOINT 
        @include responsive(s) {
            width: calc(100% - #{$gutter});
            margin: calc($gutter / 2);

            float: none;
        }
    }
}

@for $i from 1 through 12 {
    .grid-nobreak-#{$i} {
        width: calc(#{grid($i)} - #{$gutter});
        margin: calc($gutter / 2);
        //border: 1px solid $accent;

        float: left;
    }
}

@for $i from 1 through 12 {
    .grid-card-#{$i} {
        width: calc(#{grid($i)} - #{$gutter});
        margin: calc($gutter / 2);

        //background-color: $secondary;
        //border: 1px solid $accent;
        float: left;

        //BREAKPOINT 
        
        @include responsive(m) {
            width: calc(50% - #{$gutter});
            margin: calc($gutter / 2);
        }

        //QUANDO TIVER MAIS DE 5 PROJETOS MUDAR PARA 50%
        @include responsive(s) {
            width: calc(100% - #{$gutter});
            margin: calc($gutter / 2);
        }


        @include responsive(minorXs) {
            width: calc(100% - #{$gutter});
            margin: calc($gutter / 2);

            float: left;
        }
    }
}


.flex {
    display: flex;
}
.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero {
    width: 100%;
}

//START HEADER STYLE////////////////////////////////////
// Menu style

header {
    width: 100%;
    position: sticky;
    z-index: 100;
    top: 0;
}
.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $font_color--primary;
    font-weight: 400;

    li {
        margin-left: 3.2rem;
    }


    li > .active {
        color: $ternary;
    }

    .nav-link.active {
        color: $font_color--primary;
    }
    .nav-link {
        //border: 1px solid $accent;
        //width: 9.4rem;
        display: inline-block;
        padding: .8rem;
        transition: .5s ease;

        &:hover {
            color: $accent;
        }
        

        @include responsive(s) {
            width: 100%;
       }
       
       //BREAKPOINT 
       @include responsive(xs) {
        width: 100%;
       }
        
    }

    //Style Link social media
    &.social-media {
        color: $accent;
        position: relative;
        font-weight: 400;
        font-size: 1.5rem;

        & > li {
            width: auto;
            margin-left: 0;
        }

        .nav-link:hover {
                color: $accent; 
                position: relative;
                font-weight: 400;
                transition: 0.35s;
                
                &::before {
                    transform: translate(-.6rem, -.2rem) scale(1.5);
                    transition: 0.35s;
                }
        }

        .nav-link {
            display: flex;
            align-items: flex-start;
            gap: .4rem;
            transition: 0.35s;

            &::before {
                display: block;
                content: url(./../assets/svg/arrow.svg);
                background-size: 28px 28px;
                transform: translate(0, -.2rem) scale(1);
                transition: 0.35s;
            }

         }



        //BREAKPOINT 
        @include responsive(s) {
             & > li {
                margin-left: .5rem;
            }
        }
        
        //BREAKPOINT 
        @include responsive(xs) {
            & > li {
                margin-left: .5rem;
            }
        }
    }

    //BREAKPOINT 
    @include responsive(s) {
        justify-content: space-around;

        li {
            margin-left: 0;
        }

        .nav-link {
        padding: 1.6rem 1.2rem;
        }
    
    
    }

    //BREAKPOINT 
    @include responsive(xs) {
        justify-content: space-around;

        li {
            margin-left: 0;
        }

        .nav-link {
            padding: 1.6rem 1.2rem;
    }
    
    }

}

/*
---------------------------------------
hover effect 25
---------------------------------------
*/

.SMN_effect-25 a {
    letter-spacing: inherit;
    transition: 0.35s;
    font-size: 1.5rem;
    font-weight: 600;
    
    @include responsive(m) {
        letter-spacing: inherit;
        transition:0;
    }

    @include responsive(s) {
        letter-spacing: inherit;
        transition:0;
    }

    @include responsive(xs) {
        letter-spacing: inherit;
        transition: 0;
    }
}


.SMN_effect-25 a:hover, .SMN_effect-25 a:active {
    letter-spacing: 2px;
    transition: 0.35s;

    @include responsive(m) {
        letter-spacing: inherit;
        transition: 0;
    }

    @include responsive(s) {
        letter-spacing: inherit;
        transition: 0;
    }

    @include responsive(xs) {
        letter-spacing: inherit;
        transition: 0;
    }
}


//END HEADER STYLE////////////////////////////////////

//START POST////////////////////////////////////
.hero_home {
    height: 100vh;
    //padding-bottom: 85vh;
    overflow-y: auto;
    position: relative;

    @include responsive(s) {
    padding-bottom: 80vh;
    }
}


.card {
    margin-bottom: 6rem;

    @include responsive(s) {
        margin-bottom: 2rem;
    }
}

.port-container {
    padding: 6rem 0 4rem 3rem;
    
    h1 {
        white-space: pre;
        font-size: 3.5rem;
        font-weight: 400;
        color: $font_color--primary;
    }

    @include responsive(s) {
        padding: 6rem 0 4rem 0;
    }
}

.port-title {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    white-space: pre;

    h1 {
        @include responsive(m) {
            font-size: 3rem;
            color: $font_color--primary;
            
        }

        @include responsive(s) {
            font-size: 2.5rem;
            color: $font_color--primary;
            
        }

        @include responsive(xs) {
            font-size: 2rem;
            color: $font_color--primary;
            
        }

        @media screen and (max-width: 450px) {
            font-size: 1.8rem;
            color: $font_color--primary;
        }

        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
            color: $font_color--primary;
        }

        @media screen and (max-width: 350px) {
            font-size: 4vw;
            color: $font_color--primary;
        }

    }

    @include responsive(xs) {
        justify-content: center;
        text-align: center;
    }

}

.card-container {
    width: 100%;
    padding: 0 3rem;
    //position: absolute;
    opacity: 0;
    @include responsive(s) {
        padding: 0;
        position: absolute;
    }
    
}

.thumb {
    width: 100%;
    position: relative;
    box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08), 0px 2px 4px -2px rgba(24, 39, 75, 0.12);

    &:hover > .btn-external-link {
        background-color: $accent;
        opacity: 1;
        transition: 0.35s;
    }


    .btn-external-link {
        width: 3rem;
        height: 3rem;
        margin: 1rem;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: $primary;
        opacity: 0.6;
        transition: 0.35s;
        border-radius: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
            height: 1rem;
        }
        
    }
}



.card-info {
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .play-min {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        color: $font_color--secondary;

        .play-icon img {
            height: .8rem;
        }

        .min-icon {
            font-size: 1.5rem;


            @include responsive(l) {
                font-size: 1.5rem;
            }
        
            @include responsive(m) {
                font-size: 1rem;
            }
        
            @include responsive(s) {
                font-size: 1rem;
            }
        }
    }

    .card-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
        flex: 1 0 0;

        //.card-title {}

        .card-subtitle {
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 18px */
            color: $font_color--secondary;
        }
    }
}

//END POST////////////////////////////////////


//START FOOTER////////////////////////////////////

footer {
    width: 100%;

    .container {
        padding: 0rem 1rem;
    }

}
.footer {
    display: flex;
    padding: 12px 0;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    p { 
        @include typeface-02(p2, mobile);

        @include responsive(s) {
            @include typeface-02(p3, mobile);
        }
    }
}

//END FOOTER////////////////////////////////////

//START POST////////////////////////////////////

.post-container {
    padding: 0;

    .video-player {
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
}

.post-reel {
    padding: 0;

    .video-player {
        padding: 56.25% 0 0 0;
        position: relative;

        iframe {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }

        @include responsive(s) {
            padding: 120% 0 0 0;
        }

        @include responsive(m) {
            padding: 100% 0 0 0;
        }
    }
}

.post-info {
    height: 0;
    padding-bottom: 37%;
    overflow-y: auto;
    position: relative;

    @include responsive(xl) {
        overflow-y: auto;
    }

    @include responsive(s) {
        height: 52vh;
        padding-bottom: 5%;
        overflow-y: auto;
        position: unset;
    }
}

.post-info .post-content {
    position: absolute;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;

    @include responsive(s) {
        position: unset;
    }

    .play-min {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        color: $font_color--secondary;
        gap: 1rem;

        .play-icon img {
            height: .8rem;
        }

        .min-icon {
            font-size: 1.5rem;


            @include responsive(l) {
                font-size: 1.5rem;
            }
        
            @include responsive(m) {
                font-size: 1rem;
            }
        
            @include responsive(s) {
                font-size: 1rem;
            }
        }
    }

    .post-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
        flex: 1 0 0;

        .post-title {
            @include typeface-01(h1, mobile);
            color: $font_color--primary;

        }

        .post-subtitle {
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 18px */
            color: $accent;
        }
    }

    .post-description p {
        @include typeface-02(p1, desktop);
        color: $font_color--primary;
        line-height: 130%; /* 17.6px */
        font-weight: 100;

    }

    .post-role span {
        display: inline;
        @include typeface-02(p1, desktop);
        color: $font_color--primary;
        font-weight: 100;
    }
}

//GALERY POST
.gallery {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    gap: 1rem;

    .img-container:hover{
        transition: all 0.1s ease-in-out;
        opacity: 0.7;
    }

	.img-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
	}

    @include responsive(xl) {
        grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    }

    @include responsive(l) {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    @media screen and  (min-width: 666px) and (max-width: 860px) {
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    }


        @media screen and (max-width: 445px) {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    @media screen and (max-width: $container-l) and (min-width: $container-m) {
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }

    @media screen and (max-width: 445px) {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }
    
}

.modal {
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease-in-out;
}


.full-img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: auto;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.3s ease-in-out;

    @include responsive(s) {
        width: 90%;
    }   
    
  }
  
  .full-img.open{
    transform: translate(-50%, -50%) scale(1);
    z-index: 2000;
  }
  
  .modal.open{
    opacity: 1;
    pointer-events: all;
  }


  ////PRELOADER 
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
    .counter {
      position: absolute;
      z-index: 1500; 
      font-size: 23rem;
      color: rgba(255, 255, 255, 0.05);
    }
    
    .birrib_logo {
      z-index: 2000;
      height: 8%;
    }
    
    span {
      color: #fff;
      font-size: 8rem;
    }
  }
  

  //END POST////////////////////////////////////

//START ABOUT////////////////////////////////////

.about-container {
    height: 78vh;
    overflow-y: auto;
    position: relative;

    @include responsive(xl) {
        overflow-y: auto;
    }

    @include responsive(s) {
        height: 80vh;
        padding-bottom: 5%;
        overflow-y: auto;
        position: unset;
    }
}

.about {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &_title {
        font-size: 3.2rem;
    }

    &_cta {
        font-size: 1.6rem;
    }

    &_description {
        line-height: 130%;
        max-width: 44.9rem;
        font-size: 1.6rem;
        margin-bottom: 4rem;
    }

    &_contact-container {
        display: flex;
        flex-direction: column;

        .about_email, .about_phone {
            font-size: 3.2rem;

            &::before {
                margin-right: .8rem;
                display: inline;
                content: url(./../assets/svg/arrowContact.svg);
                background-size: 28px 28px;
                transform: translate(0, -.2rem) scale(2px);
                transition: 0.35s;
            }
        }

    }
}

.about {

    &_perfil {


        .photo img {
            width: 24rem;
            height: 20.9rem;
        }

        @include responsive(s) {
            padding-top: 6rem;
        }

    }

}


.perfil_info {
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    .play-min {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        color: $font_color--secondary;
        padding-top: .6rem;

        .play-icon img {
            height: 1rem;
        }

        .play-icon.world img {
            height: 1.2rem;
        }
    }

    .perfil-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
        flex: 1 0 0;

        //.perfil-title {}

        .perfil-links {
            font-size: 1.2rem;
            display: flex;
            gap: .4rem;
            text-decoration: underline;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 18px */
            color: $font_color--secondary;
        }
    }
}

.about {

    .about_vitae {
        width: 100%;
        padding: 2.4rem;
        background-color: #151515;

        .vitae_container {
            color: #8D8D8D;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        .vitae_container .vitae_section {
            display: flex;
            flex-direction: column;
            gap: .4rem;

            &--title {
                font-size: 1.6rem;
            }

            &--subtitle {
                font-size: 1.4rem;
            }
        }
    }
}
